import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Page extends PureComponent {
	render() {
		return (
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					background: 'RGBA(250,250,250,1)',
				}}
			>
				{this.props.children}
			</div>
		);
	}
}
