import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Field } from 'react-final-form';

export default class CreateReview extends PureComponent {
  static propTypes = {
    placeId: PropTypes.string,
  };

  static defaultProps = {};

  static createReviewMutation = `
    mutation createReview($input: CreateReviewInput!) {
      createReview(input: $input) { id }
    }`;

  componentDidMount() {
    this.createCafe();
  }

  createCafe() {
    const { placeId } = this.props;
    API.put('CafesRestApi', `/cafes?placeId=${placeId}`).catch(err =>
      console.error('Error creating cafe:', err)
    );
  }

  submitReview = review => {
    const { placeId } = this.props;
    API.graphql(
      graphqlOperation(this.constructor.createReviewMutation, {
        input: {
          ...review,
          rating: parseInt(review.rating, 10),
          reviewCafeId: placeId,
        },
      })
    ).then(x => console.log(x), y => console.log(y));
  };

  render() {
    return (
      <div>
        <Form
          onSubmit={this.submitReview}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="beverage">Beverage</label>
                <Field
                  component="input"
                  id="beverage"
                  name="beverage"
                  type="text"
                />
              </div>
              <div>
                <label htmlFor="bean">Bean</label>
                <Field component="input" id="bean" name="bean" type="text" />
              </div>
              <div>
                <label htmlFor="rating">Rating</label>
                <Field component="select" id="rating" name="rating">
                  <option value="">How was it?</option>
                  <option value="1">ugh!</option>
                  <option value="2">meh...</option>
                  <option value="3">good</option>
                  <option value="4">great!</option>
                </Field>
              </div>
              <input type="submit" />
            </form>
          )}
        />
      </div>
    );
  }
}
