import PropTypes from 'prop-types';
import { PureComponent } from 'react';

export default class WithLocation extends PureComponent {
  static propTypes = {
    renderPending: PropTypes.func,
    renderError: PropTypes.func,
    render: PropTypes.func,
    updatePeriod: PropTypes.number,
  };

  static defaultProps = {
    updatePeriod: 5000,
  };

  state = {
    location: null,
    error: null,
  };

  intervalId = 0;

  componentDidMount() {
    this.mounted = true;
    this.updateLocation();
    this.intervalId = setInterval(this.updateLocation, this.props.updatePeriod);
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.intervalId);
  }

  updateLocation = () => {
    if (!this.mounted) return;
    this.getLocation().then(
      ({ lat, lng, accuracy }) =>
        this.setState(prevState => {
          const { lat: oldLat, lng: oldLng, accuracy: oldAcc } =
            prevState.location || {};
          if (lat === oldLat && lng === oldLng && accuracy === oldAcc) {
            return;
          }
          return { location: { lat, lng, accuracy } };
        }),
      error => {
        console.error(error);
        this.setState({ error });
      }
    );
  };

  getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      }, reject);
    });
  }

  render() {
    const { location, error } = this.state;
    if (location) return this.props.render({ location });
    if (error) return this.props.renderError({ error });
    return this.props.renderPending({});
  }
}
