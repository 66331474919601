import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';

import { withAuthenticator } from 'aws-amplify-react';

import WithLocation from './components/WithLocation';
import CafeMap from './components/CafeMap';
import Page from './components/Page';
import CreateReview from './components/CreateReview';

Amplify.configure(aws_exports);

class MapWithLocation extends Component {
  render() {
    return (
      <WithLocation
        render={this.renderMap}
        renderPending={() => <div>Please grant location access</div>}
        renderError={() => <div>Could'nt get location D:</div>}
      />
    );
  }

  renderMap({ location }) {
    return <CafeMap location={location} />;
  }
}

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Fragment>
          <Route path="/" component={MapWithLocation} />
          <Switch>
            <Route
              path="/cafe/:id"
              render={({ match }) => (
                <Page>
                  <CreateReview placeId={match.params.id} />
                </Page>
              )}
            />
            <Route path="/account" render={({ match }) => <Page />} />
            <Route path="/about" render={({ match }) => <Page />} />
            <Route path="/contact" render={({ match }) => <Page />} />
          </Switch>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default withAuthenticator(App);
