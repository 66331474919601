import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationMarker from './LocationMarker';

import { Link } from 'react-router-dom';

const CafeMarker = ({ id, name }) => <Link to={`/cafe/${id}`}>{name}</Link>;

export default class CafeMap extends PureComponent {
  static propTypes = {
    renderPending: PropTypes.func,
    renderError: PropTypes.func,
    render: PropTypes.func,
    defaultZoom: PropTypes.number,
  };

  static defaultProps = {
    defaultZoom: 15,
  };

  state = {
    searchedLocation: null,
    cafes: [],
    zoom: this.props.defaultZoom,
  };

  fetchPlaces = () => {
    if (this.props.location === this.state.searchedLocation) {
      return;
    }
    const { maps, map } = this.google;
    const mapBounds = map.getBounds();
    const mapEdges = {
      north: mapBounds.getNorthEast().lat(),
      east: mapBounds.getNorthEast().lng(),
      south: mapBounds.getSouthWest().lat(),
      west: mapBounds.getSouthWest().lng(),
    };
    const service = new maps.places.PlacesService(map);
    var request = {
      location: this.props.location,
      radius: '1500',
      locationBias: mapEdges,
      type: ['cafe'],
    };
    service.nearbySearch(request, (results, status, meta) =>
      this.setState({ searchedLocation: this.props.location, cafes: results })
    );
  };

  handleApiLoaded = api => {
    this.google = api;
    this.setState({ zoom: api.map.getZoom() });
    this.fetchPlaces();
    api.maps.event.addListener(api.map, 'zoom_changed', () =>
      this.setState({ zoom: api.map.getZoom() })
    );
  };

  componentDidMount() {
    this.setState({ defaultCenter: this.props.location });
  }

  render() {
    const { location, defaultZoom } = this.props;
    if (!this.state.defaultCenter) return null;
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDHP38fceioOv00ZvcZdI93ZqOwJfb-OXg',
            libraries: ['places'],
          }}
          defaultCenter={this.state.defaultCenter}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleApiLoaded}
        >
          {this.google && (
            <LocationMarker
              lat={location.lat}
              lng={location.lng}
              accuracy={location.accuracy}
              zoom={this.state.zoom}
            />
          )}
          {this.state.cafes.map(cafe => (
            <CafeMarker
              key={cafe.reference}
              id={cafe.reference}
              name={cafe.name}
              lat={cafe.geometry.location.lat()}
              lng={cafe.geometry.location.lng()}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}
