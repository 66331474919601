// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4478695a-75c7-437e-9243-70d0952b9bec",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_YIwrkqi5e",
    "aws_user_pools_web_client_id": "328iii2ctuctug70suhgsqaeg7",
    "aws_appsync_graphqlEndpoint": "https://fmq7rsxeznaoxprd56x4viyug4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "CafesRestApi",
            "endpoint": "https://kfw4ojtl0a.execute-api.eu-west-1.amazonaws.com/Prod",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "coffeesnob-20181224205022-hostingbucket",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2tjg5rydlppa2.cloudfront.net"
};


export default awsmobile;
