import PropTypes from 'prop-types';
import React from 'react';

function getScale(lat, zoom) {
  return (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);
}

export default ({ lat, accuracy, zoom }) => {
  let center;
  center = Math.max(
    accuracy ? parseInt(accuracy / getScale(lat, zoom + 1), 10) : 0,
    10
  );
  const width = center * 2;
  return (
    <svg
      viewBox={`0,0,${width},${width}`}
      style={{
        width: width,
        height: width,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <defs>
        <clipPath id="view-clip" clipPathUnits="userSpaceOnUse">
          <circle cx={center} cy={center} r={center} />
        </clipPath>
      </defs>
      <circle
        cx={center}
        cy={center}
        r={center}
        fill="#5d97f7"
        fillOpacity="0.3"
        stroke="#5d97f7"
        strokeWidth="2"
        strokeOpacity="0.5"
        clipPath="url(#view-clip)"
      />
      <circle cx={center} cy={center} r="8" fill="white" />
      <circle cx={center} cy={center} r="6" fill="#2884fc" />
    </svg>
  );
};
